
































































































// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, ref, reactive } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import Page from '@/components/Page.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'PagePricing',
  components: {
    Page
  },
  setup() {
    const types = ref(['legend', 'standard', 'standard-plus', 'professional', 'enterprise']);

    const headers = ref([
      {
        id: 'standard',
        type: 'Standard',
        price: '40',
        desc: 'To start with clinical assessements'
      },
      {
        id: 'standard-plus',
        type: 'Standard +',
        price: '95',
        desc: 'Complete mouvement analysis platform and support'
      },
      {
        id: 'professional',
        type: 'Professional',
        price: '220',
        desc: 'Extensive movement analysis platform for up to 7 users'
      },
      {
        id: 'enterprise',
        type: 'Enterprise',
        price: '390',
        desc: 'Unlimited movement analysis platform and support'
      }
    ]);

    const content = reactive({
      legend: [
        {
          name: 'Podosmart platform',
          desc: 'A cupiditate tempore porro adipisci perferendis in provident, pariatur alias odit ipsam'
        },
        'License',
        'Podosmart insoles technology',
        '# Analysis per month',
        'Onboarding',
        'Marketing pack',
        'Technical training',
        'Clinical ressources',
        'Biomechanical training',
        'Clinical expert support',
        'Premium technical service'
      ],
      standard: ['✓', '✓', '2 200 €', '40', '2 days', '✓', 'X', 'X', 'X', 'X', 'X'],
      'standard-plus': ['✓', '✓', '500 €', '70', '2 weeks', '✓', '✓', '✓', '✓', '✓', 'X'],
      professional: ['✓', '✓', '✓', '300', '1 month', '✓', '✓', '✓', '✓', '✓', '✓'],
      enterprise: ['✓', '✓', '✓', 'unlimited', '1 year', '✓', '✓', '✓', '✓', '✓', '✓']
    });

    const contentPlus = reactive({
      legend: ['# users', 'Commitment'],
      standard: ['1', '12 months'],
      'standard-plus': ['1', '24 months'],
      professional: ['3', '12 months'],
      enterprise: ['unlimited', '12 months']
    });

    const options = reactive({
      legend: [
        {
          name: 'Test and analysis add-ons',
          desc: 'A cupiditate tempore porro adipisci perferendis in provident',
          subdesc: 'coming soon'
        },
        '+30 analysis',
        'Teleconsultation'
      ],
      standard: ['70 €', '40 €', '10 €'],
      'standard-plus': ['40 €', '40 €', '6 €'],
      professional: ['✓', '25 €', '4 €'],
      enterprise: ['✓', 'unlimited', '✓']
    });

    function getFontColor(value: string) {
      switch (value) {
        case '✓':
          return '#106FCE';
        case 'X':
          return '#C9CFD3';
        default:
          return '#3972A7';
      }
    }

    return {
      types,
      headers,
      content,
      contentPlus,
      options,
      // Methods
      getFontColor
    };
  }
});
